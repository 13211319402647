.login_container {
  display: flex;
  flex-direction: column;
  width: 60%;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

.login_page {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  padding: 30px;
  background-color: $white;
  height: 100vh;
  min-height: 400px;
}


.login_title {
  color: $text-color-black;
  font-family: $font-family-poppins;
  font-size: 30px;
  font-weight: 700;
  line-height: 36.57px;
}

.login_container>label,
.login_container>input,
.login_container>button,
.password_options {
  width: 60%;
  font-family: $font-family-poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
}

.login_container_label {
  color: #4d5959;
  margin-bottom: 4px;
  margin-top: 26px;
  font-family: $font-family-poppins;
  font-size: 14px;
  font-weight: 550;
  width: 64%;
}

.login_container>input,
.login_container>input:hover,
.login_container>input:focus,
.login_container>input:active {
  height: 53px;
  border: 1px solid $danger !important;
  background: none;
  border-radius: 8px;
  padding: 0px 16px;
}

.password_options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
}

.password_options>span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.password_options>span label {
  color: $text-color-black;
  font-family: $font-family-poppins;
  font-size: 14px;
  font-weight: 400;
}

.password_options>a {
  color: $danger;
  text-decoration: none;
  font-family: $font-family-poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.63px;
  text-align: left;
}



.input_container {
  display: flex;
  background-color: $white;
  width: 60%;
  gap: 8px;
  height: 55px;
  border: 1px solid #7c7c7c !important;
  border-radius: 8px;

  position: relative;
  min-width: 260px;
  margin-top: 24px;
}

.input_container_changePassword {
  width: 100%;
  margin-top: 0px;
  border: 1px solid #d8d8d8 !important;
}

.input_container>img {
  height: $select-height;
  width: $select-height;
  margin-top: 8px;
  position: absolute;
  top: 0px;
  left: 24px;
}

.input_container i {
  color: #7c7c7c;
}

@media (max-width: 715px) {
  .login_page>img {
    display: none;
  }

  .login_container {
    width: 100%;
  }
}

.input_container>input {
  border: none !important;
  width: 100%;
  padding-left: 81px;
  border-radius: 8px;
  min-height: 53px;
}

.input_container>input:hover,
.input_container>input:active,
.input_container>input:focus {
  border: 2px solid blue;
}

.my-2 {
  display: flex;
}

.forgot_password_title {
  line-height: 58px;
  display: flex;
  justify-content: center;
  font-family: $font-family-poppins;
  font-size: $select-height;
  font-weight: 500 !important;
  margin-top: 20%;
}

.forgot_password_sub_title {
  color: $form-label-color;

  font-size: 14px;
  font-weight: 550;
  display: flex;
  justify-content: left;
  width: 100%;
  padding-left: 20%;
  margin-bottom: 24px;
  margin-top: 24px;
  font-family: $font-family-poppins;

  line-height: 18px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}

.forgot_password_label {
  line-height: 44px;
  display: flex;
  justify-content: left;
  margin-left: 5%;
  color: #4d5959;
  font-family: $font-family-poppins;
  font-size: 16px;
  font-weight: 550;
}

.forgot_password_radio {
  background-color: $disabled-color-bg;
  width: 60%;
  border-radius: 8px;
  padding: 11px 20px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  color: $form-label-color;
  font-size: 12px;
  line-height: 18px;
}

.forgot_password_otp {
  display: flex;
  justify-content: left;
  gap: 16px;

  width: 100%;

  padding-left: 20%;
}

.forgot_password_otp>input {
  width: 63px;
  height: 57px;
  text-align: center;
  border: 0.75px solid #9badca;
}

.forgot_password_otp_timer {
  color: $danger;
  margin-top: 16px;
}

.forgot_password_resend {
  color: $form-label-color;
  margin-top: 16px;
  font-family: $font-family-poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
}

.forgot_password_resend_link,
.forgot_password_resend a {
  color: $danger;
  margin-top: 4px;
  text-decoration: none;
  background: none;
  border: none;
}

.forgot_password_successfully {
  display: flex;
  justify-content: center;

  color: $form-label-color;
  margin: 32px 0px;

  font-family: $font-family-poppins;
  font-size: 30px;
  font-weight: 700 !important;
  line-height: 39.81px;
  text-align: center;
}

.forgot_password_sub_title_successfully {
  color: $form-label-color;
  font-size: 12px;

  font-family: $font-family-poppins;
  font-weight: 500;
  line-height: 18.37px;
  letter-spacing: 0.11484018713235855px;
  text-align: center;
}

.login_bg_image {
  position: absolute;
  right: 0%;
  height: 94%;
}

.carousel-item-start,
.carousel-item-prev,
.carousel-item-end {
  position: absolute !important;
  right: 0% !important;
  height: 94% !important;
}

.carousel-item>img {
  height: 95vh;
  width: 40vw;
}

.carousel-item {
  width: 40vw;
}

@media (max-width: 600px) {
  .carousel-item>img {
    display: none !important;
  }
}

.carousel-indicators>button {
  width: 16.76px !important;
  height: 16.76px !important;
  background: #b3b3b3;
  border-radius: 120px !important;
  box-sizing: inherit !important;
  border-top: 0px !important;
  border-bottom: 0px !important;
  padding: 7.62px !important;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-webkit-reveal {
  display: none;
}


///// NEW UI Screens
.login_page {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  padding: 30px;
  background-color: $white;
  position: relative;
  overflow: auto;
  // height: 100%;

  height: 500px;
  border-radius: 24px;
  width: 100%;
  max-width: 1008px;
}

.login_page_bg {
  position: relative;
  background-image: url('../../images/bg/login_page_bg.png');
  background-size: cover;
  background-position: center;
  height: 100vh;
  min-height: 694px;
  // height: 100%;
  width: 100%;

  padding: 5.5% 7%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Add the overlay using the ::before pseudo-element */
.login_page_bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
}

/* Ensure the content inside the login page appears above the overlay */
.login_page_bg>* {
  position: relative;
  z-index: 2;
}

img.tcrcLogo_login_bg {
  height: 100%;
  width: 40%;
}

.loginBtnContainer {
  width: 60%;
  display: flex;
  justify-content: right;
  align-items: center;
}

.loginBtnContainer>button {
  background-color: $danger;
  color: $white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-family-poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: left;
  width: 100px;
  height: 46px;
  padding: 12px;
  gap: 8px;
  border-radius: 4px;
}

.loginBtnContainer>button:hover {
  background: #8D170A;

}

.tcrcLogo_login {
  position: absolute;
  top: 26px;
  right: 56px;
}